import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://rhq.vn/api/',
  timeout: 60000,
  headers: {'X-Custom-Header': 'foobar', 'X-Author' : 'Devculi'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
